import { render } from "./Login.vue?vue&type=template&id=38beb2ea&scoped=true"
import script from "./Login.vue?vue&type=script&lang=js"
export * from "./Login.vue?vue&type=script&lang=js"

import "../../../public/assets/vendor/css/authentication/form-1.css?vue&type=style&index=0&id=38beb2ea&scoped=true&lang=css"
import "../../../public/assets/vendor/css/forms/theme-checkbox-radio.css?vue&type=style&index=1&id=38beb2ea&scoped=true&lang=css"
import "../../../public/assets/vendor/css/forms/switches.css?vue&type=style&index=2&id=38beb2ea&scoped=true&lang=css"
import "./Login.vue?vue&type=style&index=3&id=38beb2ea&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-38beb2ea"

export default script