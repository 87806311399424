<template>
    <section class="form">
        <!-- <request-loader></request-loader> -->
        <div class="form-container">
        <div class="form-form">
            <div class="form-form-wrap">
                <div class="form-container">
                    <div class="form-content mt-5">
                        <h3 class="text-center font-weight-bold">{{ config.appName }}</h3>
                        <h4 class="text-center">Get started with a free account</h4>
                        <p class="signup-link">Already have an account? <router-link to="/login">Log in</router-link></p>
                        <form @submit.prevent="registerUser" class="text-left">
                            <div class="form">
                                <validation-errors :errors="validationErrors" v-if="validationErrors"></validation-errors>
                                <div class="field-wrapper input">
                                    <select name="account_type" id="account_type" class="form-control" v-model="account_type" required>
                                        <option value="" disabled>I am</option>
                                        <option value="individual">An individual</option>
                                        <option value="business">A business</option>
                                    </select>
                                </div>
                                <template v-if="account_type == 'individual'">
                                    <div class="field-wrapper input" id="firstname-field">
                                        <i class="fa fa-user"></i>
                                        <input type="text" class="form-control" v-model="first_name" name="firstname" id="firstname" placeholder="Firstname" required>
                                    </div>
                                    <div id="last-field" class="field-wrapper input">
                                        <i class="fa fa-user"></i>
                                        <input id="lastname" name="lastname" v-model="last_name" type="text" class="form-control" placeholder="Lastname" required>
                                    </div>
                                </template>
                                <template v-if="account_type == 'business'">
                                    <div class="field-wrapper input" id="businessname-field">
                                        <i class="fa fa-user"></i>
                                        <input type="text" class="form-control" v-model="business_name" name="business_name" id="business_name" placeholder="Business Name" required>
                                    </div>
                                </template>
                                <div class="field-wrapper input" id="phone-field">
                                    <i class="fa fa-phone"></i>
                                    <input type="tel" name="phone" id="phone" v-model="phone" class="form-control" placeholder="Phone number" required>
                                </div>
                                <div id="email-field" class="field-wrapper input">
                                    <i class="fa fa-at"></i>
                                    <input id="email" name="email" type="text" placeholder="Email" v-model="email" required>
                                </div>
                                <div id="password-field" class="field-wrapper input mb-2">
                                    <i class="fa fa-lock"></i>
                                    <input id="password" name="password" type="password" placeholder="Password" v-model="password">
                                </div>
                                <div class="field-wrapper terms_condition">
                                    <div class="n-chk new-checkbox checkbox-outline-primary">
                                        <label class="new-control new-checkbox checkbox-outline-primary">
                                          <input type="checkbox" class="new-control-input" required>
                                          <span class="new-control-indicator"></span><span>I agree to the <router-link to="/pages/terms-general" target="_blank">  terms and conditions </router-link></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="d-sm-flex justify-content-between">
                                    <div class="field-wrapper toggle-pass">
                                        <p class="d-inline-block">Show Password</p>
                                        <label class="switch s-warning">
                                            <input type="checkbox" id="toggle-password" class="d-none">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                    <div class="field-wrapper">
                                        <button class="btn btn-warning" type="submit">Get Started!</button>
                                    </div>
                                </div>

                            </div>
                        </form>
                        <p class="terms-conditions">© {{ new Date().getFullYear() }} All Rights Reserved. <a href="/">{{ config.appName }}</a> </p>

                    </div>
                </div>
            </div>
        </div>
        <div class="form-image">
            <div class="side-image" :style="{backgroundImage: 'url(' + require('@/assets/home/delivered-package.png') + ')'}" style="position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 1.5;
                            background-color: #060818;
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position-x: center;
                            background-position-y: center;">
            </div>
        </div>
    </div>
    </section>
</template>

<script>
import appconfig from '../../appconfig'
import ValidationErrors from '../../components/ValidationErrors.vue';
import instance from '../../presenter/api/network';
import { useToast } from "vue-toastification";
const toast = useToast();
export default{
  components: { ValidationErrors }, 
    mounted () {
        const authFormJS = document.createElement('script')
        authFormJS.setAttribute('src', 'assets/vendor/js/authentication/form-1.js')
        document.head.appendChild(authFormJS)
    },
    data(){
        return {
            registration_method: "email_and_password",
            account_type: "",
            first_name: "",
            last_name: "",
            business_name: "",
            phone: "",
            email: "",
            password: "",
            config: appconfig,
            validationErrors: "",
        }
    },
    methods: {
        resetData () {
            this.validationErrors = "";
        },
        registerUser () {
            this.resetData();
            this.$root.startRequestLoader();
            this.loading = true;
            
            
            let data = {
                registration_method: this.registration_method, 
                account_type: this.account_type,
                first_name: this.first_name, 
                last_name: this.last_name, 
                business_name: this.business_name,
                phone: this.phone, 
                email: this.email, 
                password: this.password,
                frontend_url: appconfig.appBaseUrl() + "login"
            };
            instance.post("/customer/register", data).then(res => {
                this.$root.endRequestLoader();
                toast.success("Your account has been created successfully.\nA confirmation email has been sent to you, please check your email inbox to confirm your account.");
                this.$router.push("/login");
            }).catch(err => {
                if (err.response.status == 400) {
                    
                    this.validationErrors = err.response.data.data;
                    console.log(this.validationErrors)
                } else if (err.response.status == 401) {
                    toastr.error("Incorrect username or password");
                }
                this.$root.endRequestLoader();
            })
        }
  }
}
</script>

<style src='../../../public/assets/vendor/css/authentication/form-1.css' scoped></style>
<style scoped src='../../../public/assets/vendor/css/forms/theme-checkbox-radio.css'>
</style>
<style scoped src='../../../public/assets/vendor/css/forms/switches.css'></style>
<style scoped>
/* @import url('../../../public/assets/vendor/css/authentication/form-1.css');
@import url('../../../public/assets/vendor/css/forms/theme-checkbox-radio.css');
@import url('../../../public/assets/vendor/css/forms/switches.css'); */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");
</style>
