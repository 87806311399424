<template>
    <div>
        <section class="form">
            <div class="form-container">
                <div class="form-form">
                    <div class="form-form-wrap">
                        <div class="form-container">
                            <div class="form-content" >
                                <transition name="fade">
                                    <div v-if="active_component == 'login'">
                                        <h3 class="text-center font-weight-bold">{{ config.appName }}</h3>
                                        <h4 class="text-center">Log In to your account</h4>
                                        <p class="signup-link text-center">New Here? <router-link to="/register">Create an account</router-link></p>
                                        <form class="text-left" @submit.prevent="onSubmit">
                                            <div class="form">
                                                <validation-errors :errors="validationErrors"></validation-errors>
                                                <div id="email-field" class="field-wrapper input">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                    <input name="email" type="email" class="form-control" placeholder="Email" v-model="email" required>
                                                </div>
            
                                                <div id="password-field" class="field-wrapper input mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                                    <input id="password" name="password" :type="password_field" class="form-control" placeholder="Password" v-model="password" required>
                                                </div>
                                                <div class="d-sm-flex justify-content-between">
                                                    <div class="field-wrapper toggle-pass">
                                                        <p class="d-inline-block">Show Password</p>
                                                        <label class="switch s-warning">
                                                            <input type="checkbox" id="toggle-password" class="d-none" @click="togglePassword">
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                    <div class="field-wrapper">
                                                        <button type="submit" class="btn btn-warning">Log In</button>
                                                    </div>
            
                                                </div>
            
                                                <!-- <div class="field-wrapper text-center keep-logged-in">
                                                    <div class="n-chk new-checkbox checkbox-outline-primary">
                                                        <label class="new-control new-checkbox checkbox-outline-primary">
                                                        <input type="checkbox" class="new-control-input">
                                                        <span class="new-control-indicator"></span>Keep me logged in
                                                        </label>
                                                    </div>
                                                </div> -->
            
                                                <div class="field-wrapper">
                                                    <a href="javascript:void(0)" class="forgot-pass-link" @click="toggleComponent">Forgot Password?</a>
                                                </div>
            
                                            </div>
                                        </form>
                                    </div>
                                </transition>
                                <transition name="fade">
                                    <div v-if="active_component == 'forgotpassword'">
                                        <h3 class="text-center font-weight-bold">{{ config.appName }}</h3>
                                        <h4 class="text-center">Forgot your password?</h4>
                                        <p>Enter your email, we will send you an email with a password reset link right away</p>
                                        <form @submit.prevent="sendPasswordResetLink">
                                            <div class="form">
                                                <validation-errors :errors="validationErrors"></validation-errors>
                                                <div id="email-field" class="field-wrapper input">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                    <input name="email" type="email" class="form-control" placeholder="Your email" v-model="email" required>
                                                </div>
                                                <div>
                                                    <button class="btn btn-warning" type="submit">Send reset link</button>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="field-wrapper">
                                                <a href="javascript:void(0)" class="forgot-pass-link" @click="toggleComponent">Want to log in now?</a>
                                            </div>
                                        </form>
                                    </div>
                                </transition>
                                
                                <p class="terms-conditions">© {{ new Date().getFullYear() }} All Rights Reserved. <a href="/">{{ config.appName }}</a> </p>
    
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-image">
                    <div class="side-image" :style="{backgroundImage: 'url(' + require('@/assets/home/delivered-package.png') + ')'}" style="position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 1.5;
                            background-color: #060818;
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position-x: center;
                            background-position-y: center;">
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import '../../../public/assets/vendor/js/authentication/form-1.js'
import appconfig from '../../appconfig';
import config from "../../appconfig";
import ValidationErrors from '../../components/ValidationErrors.vue';
import Auth from '../../helpers/auth.js';
import instance from '../../presenter/api/network.js';
export default {
    components: {ValidationErrors},
    mounted(){
        let active_component = this.$route.query.relaction;
        if(['login', 'forgotpassword'].includes(active_component)){
            this.active_component = active_component;
        }else{
            this.active_component = "login";
        }
    },
    watch:{
        '$route.query.relaction': function(relaction){
            if(['login', 'forgotpassword'].includes(relaction)){
                this.active_component = relaction;
            }else{
                this.active_component = "login";
            }
        }
    },
    data(){
        return {
            password_field: "password",
            email: "",
            password: "",
            config: config,
            validationErrors: "",
            active_component: "",
            account_not_verified: false,
        }
    },
    methods: {
        toggleComponent(){
            if (this.active_component == "login"){
                this.active_component = "forgotpassword";
            }else{
                this.active_component = "login";
            }
            this.$router.replace({query: { relaction: this.active_component} })
        },
        togglePassword(){
            if (this.password_field == 'password'){
                this.password_field = "text";
            }else{
                this.password_field = "password";
            }
        },
        onSubmit(){
            let data = {
                email: this.email,
                password: this.password,
                login_method: "email_and_password",
            }
            this.$root.startRequestLoader();
            
            instance.post("customer/login", data).then(res => {
                let response = res.data.data;
                let token = response.token.access_token;
                let user = response.user;
                Auth.saveToken(token);
                Auth.saveUser(user);
                
                this.$root.endRequestLoader();
                const intendedUrl = localStorage.getItem("intended_url");
                if (!intendedUrl || intendedUrl == undefined) {
                    // return this.$router.push("/dashboard");
                    window.location.hash = "/create-order";
                } else {
                    localStorage.removeItem("intended_url");
                    window.location.hash = intendedUrl
                }
                
                window.location.reload();
            }).catch(err => {
                this.$root.endRequestLoader();
                if (err.response.status == 400) {
                    this.validationErrors = err.response.data.data;
                    if (err.response.data.message = "unverified"){
                        this.account_not_verified = true;
                    }
                } else if (err.response.status == 401) {
                    toastr.error("Incorrect username or password");
                }
            })
        },
        sendPasswordResetLink(){
            let data = {
                email: this.email,
                frontend_url: appconfig.appBaseUrl() + "reset-password/confirm-password-reset"
            }
            this.$root.startRequestLoader();
            instance.get("customer/reset-password", {params: data}).then(res => {
                useToast().success("A password reset link has been sent to your email, check your email inbox now to reset your password");
                // let resetToken = res.data.extra_data;
                // this.$router.push("/reset-password/" + resetToken);
            }).catch(err => {
                if (err.response){
                    useToast().error(err.response.data?.data ?? "There was a problem sending your password reset link, please try again")
                }else{
                    useToast().error("An unknown error occured");
                }
                // useToast().error("There was a problem sending your password reset link");
            }).finally(() => {
                this.$root.endRequestLoader();
            })
        }
    }
}
</script>

<style src='../../../public/assets/vendor/css/authentication/form-1.css' scoped></style>
<style scoped src='../../../public/assets/vendor/css/forms/theme-checkbox-radio.css'>
</style>
<style scoped src='../../../public/assets/vendor/css/forms/switches.css'>
</style>
<style scoped>
/* @import url('../../../public/assets/vendor/css/authentication/form-1.css');
@import url('../../../public/assets/vendor/css/forms/theme-checkbox-radio.css');
@import url('../../../public/assets/vendor/css/forms/switches.css'); */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
