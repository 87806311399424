import { render } from "./Register.vue?vue&type=template&id=df363970&scoped=true"
import script from "./Register.vue?vue&type=script&lang=js"
export * from "./Register.vue?vue&type=script&lang=js"

import "../../../public/assets/vendor/css/authentication/form-1.css?vue&type=style&index=0&id=df363970&scoped=true&lang=css"
import "../../../public/assets/vendor/css/forms/theme-checkbox-radio.css?vue&type=style&index=1&id=df363970&scoped=true&lang=css"
import "../../../public/assets/vendor/css/forms/switches.css?vue&type=style&index=2&id=df363970&scoped=true&lang=css"
import "./Register.vue?vue&type=style&index=3&id=df363970&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-df363970"

export default script